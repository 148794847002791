<script setup>

</script>

<template>
<p>Projects</p>
</template>

<style scoped>

</style>