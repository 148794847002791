<script setup>

</script>

<template>
<p>Here is progress view when progress is clicked</p>
</template>

<style scoped>

</style>